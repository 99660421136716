<script setup lang="ts">
import icon_gold from '~/assets/image/icon/icon_gold@2x.png'
import { handleCopy } from '~/utils/copy'
import type { ORDER_STATUS } from '~/enums/order_status'
import { label, icon } from '~/enums/order_status'

const onClickLeft = () => history.back()
const historyParams: OrderRecord = JSON.parse(window.localStorage.getItem('flowDetail') || '{}')
</script>

<template>
  <div h-screen>
    <van-nav-bar title="充值详情" left-arrow @click-left="onClickLeft" />
    <div m-4>
      <div mb-8 flex justify-center>
        <div h-56 w-full flex flex-col items-center justify-center class="bg">
          <img h-12 w-12 :src="icon_gold" />
          <div m-4 text-5xl ct-e8e8e8>
            {{ historyParams.orderAmount }}
          </div>
          <div h-10 flex flex-row items-center>
            <img mr-2 h-5 w-5 :src="icon(historyParams.orderStatus as ORDER_STATUS)" />
            <span texy-base ct-E8E8E8>{{ label(historyParams.orderStatus as ORDER_STATUS) }}</span>
          </div>
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>金额</div>
        <div text-sm ct-e8e8e8>
          {{ historyParams.orderAmount }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>购买数量</div>
        <div text-sm ct-e8e8e8>
          {{ historyParams.orderCoinAmount }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>加赠数量</div>
        <div text-sm ct-e8e8e8>
          {{ historyParams.bonusCoinAmount }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>状态</div>
        <div text-sm ct-e8e8e8>
          {{ label(historyParams.orderStatus as ORDER_STATUS) }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>交易类型</div>
        <div text-sm ct-e8e8e8>
          {{ '充值' }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>付款方式</div>
        <div text-sm ct-e8e8e8>
          {{ historyParams.payName }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>交易时间</div>
        <div text-sm ct-e8e8e8>
          {{ historyParams.eventTime }}
        </div>
      </div>
      <div mb-3 flex justify-between>
        <div text-xs ct-5c5e69>订单号</div>
        <div flex items-center text-sm ct-e8e8e8>
          <span
            mr-2
            w-10
            border-b-bluegray
            rounded
            text-center
            text-sm
            bg-414660
            ct-cfcfcf
            @click="handleCopy(historyParams.eventId)"
            >复制</span
          >
          {{ historyParams.eventId }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
.bord {
  border-bottom: 1px solid #41466040;
}
.bg {
  background: linear-gradient(180deg, rgba(46, 52, 84, 0.5) 0%, rgba(36, 41, 67, 0.5) 100%);
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
